import {
  CogIcon,
  DocumentReportIcon,
  DownloadIcon,
  RefreshIcon,
  ShieldCheckIcon,
  TrashIcon,
  UserCircleIcon,
  CalendarIcon,
} from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import PageNumbers from "../../../components/PageNumbers/PageNumbers";
import SearchForm from "../../../components/SearchFormV3/SearchForm";
import Sidebar from "../../../components/Sidebar/Sidebar";
import SortButton from "../../../components/SortButton/SortButton";
import { apiV3 } from "../../../utils/helpers/api";
import { classNames } from "../../../utils/helpers/misc";
import { useNavigate } from "react-router-dom";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { usePageInitialization } from "../../../hooks/usePageInitialization";

export default function ReportsV3() {
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const [reports, setReports] = useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [newPageNumber, setNewPageNumber] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | "">("DESC");
  const [sortField, setSortField] = useState<string>("createdAt");
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchField, setSearchField] = useState<string>("");
  const [type, setType] = useState<string>("");
  useAuthToken();
  const { isInitiated } = usePageInitialization();

  useEffect(() => {
    if (isInitiated) fetchReports();
  }, [pageNumber, rowsPerPage, searchQuery, searchField, sortOrder, sortField, type, isInitiated]);

  const fetchReports = async () => {
    setLoading(true);
    try {
      const reports = await apiV3.getReports(
        pageNumber,
        rowsPerPage,
        searchQuery,
        searchField,
        sortOrder,
        sortField,
        type
      );
      setTotalCount(reports.data.totalCount);
      setReports(reports.data.data);
    } catch (error) {
      console.error(
        `Error occurred while fetching ${rowsPerPage} reports of page ${pageNumber} - `,
        error
      );
    }
    setLoading(false);
  };

  const downloadReportFile = async (report: any) => {
    setLoading(true);
    // Get file details from database
    try {
      apiV3.downloadReportFile(report.id).then((reportFileRes) => {
        const blob = new Blob([reportFileRes.data], {
          type: "text/csv;charset=utf-8;",
        });

        // Download file
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", report.reportFileName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    } catch (error) {
      setError(true);
      console.error(`Error occurred while fetching imported ASIC file`, error);
    }
    setLoading(false);
  };

  const deleteReport = async (reportId: string) => {
    setLoading(true);
    try {
      await apiV3.deleteReportById(reportId);
      await fetchReports();
    } catch (error) {
      console.error("Error occurred while deleting report - ", error);
    }
    setLoading(false);
  };

  const updateSortCriteria = (newSortField: string) => {
    setSortField(newSortField);
    if (newSortField === sortField) {
      if (sortOrder === "DESC") setSortOrder("ASC");
      else setSortField("");
    } else {
      setSortOrder("DESC");
    }
  };

  const tabs = [
    {
      name: "All",
      onClick: () => setType(""),
      icon: DocumentReportIcon,
      current: type === "",
    },
    {
      name: "Person Audits",
      onClick: () => setType("Person Audit"),
      icon: ShieldCheckIcon,
      current: type === "Person Audit",
    },
    {
      name: "Self Serve Audits",
      onClick: () => setType("Self Serve Audit"),
      icon: UserCircleIcon,
      current: type === "Self Serve Audit",
    },
    {
      name: "Face Match Log",
      onClick: () => setType("Face Match Log"),
      icon: CalendarIcon,
      current: type === "Face Match Log",
    }
  ];

  const updateRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
    setPageNumber(1);
  };

  return (
    <>
      <Sidebar />
      <div className="flex flex-col flex-1 md:pl-64">
        <main className="flex-1">
          {/* Replace with your content */}
          <div className="py-4">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="flex flex-row items-center text-2xl font-bold text-gray-900">
                    <DocumentReportIcon className="h-6 w-6 mr-1.5" />
                    Reports
                  </h1>
                  <p className="mt-2 text-sm text-gray-700">
                    A list of all the reports in AEV including their details.
                  </p>
                </div>
                <div className="flex flex-row items-center mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  {type !== "" && (
                    <button
                      onClick={() => navigate(`/v3/reports/configure/${type}`)}
                      type="button"
                      disabled={loading}
                      className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto disabled:opacity-60 disabled:hover:bg-blue-500"
                    >
                      <CogIcon className="h-5 w-5 mr-1.5" />
                      Configure
                    </button>
                  )}

                  <button
                    type="button"
                    disabled={loading}
                    onClick={() => fetchReports()}
                    className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm sm:ml-2 bg-stone-600 hover:bg-stone-700 focus:outline-none focus:ring-2 focus:ring-stone-500 focus:ring-offset-2 sm:w-auto disabled:hover:bg-stone-600"
                  >
                    <RefreshIcon className="h-5 w-5 mr-1.5" />
                    Refresh
                  </button>
                </div>
              </div>
              <div className="flex flex-col mt-4">
                <div className="mb-4">
                  <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                      id="tabs"
                      name="tabs"
                      className="block w-full border-gray-300 rounded-md focus:border-amber-500 focus:ring-amber-500"
                      defaultValue={tabs.find((tab) => tab.current)!.name}
                    >
                      {tabs.map((tab) => (
                        <option onClick={tab.onClick} key={tab.name}>
                          {tab.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden sm:block">
                    <div className="border-b border-gray-200">
                      <nav className="flex -mb-px space-x-8" aria-label="Tabs">
                        {tabs.map((tab) => (
                          <a
                            key={tab.name}
                            onClick={tab.onClick}
                            className={classNames(
                              tab.current
                                ? "border-amber-500 text-amber-600"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium cursor-pointer"
                            )}
                            aria-current={tab.current ? "page" : undefined}
                          >
                            <tab.icon
                              className={classNames(
                                tab.current
                                  ? "text-amber-500"
                                  : "text-gray-400 group-hover:text-gray-500",
                                "-ml-0.5 mr-2 h-5 w-5"
                              )}
                              aria-hidden="true"
                            />
                            <span>{tab.name}</span>
                          </a>
                        ))}
                      </nav>
                    </div>
                  </div>
                </div>
                <SearchForm
                  searchOptions={[
                    { name: "Filename", value: "reportFileName" },
                    { name: "Type", value: "type" },
                    { name: "Frequency", value: "frequency" },
                  ]}
                  updateSearchQueryAndField={(newSearchQuery, newSearchField) => {
                    setSearchQuery(newSearchQuery);
                    setSearchField(newSearchField);
                  }}
                />
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Created At
                                <SortButton
                                  active={sortField === "createdAt"}
                                  sortOrder={sortOrder}
                                  sortField={"createdAt"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Filename
                                <SortButton
                                  active={sortField === "reportFileName"}
                                  sortOrder={sortOrder}
                                  sortField={"reportFileName"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Type
                                <SortButton
                                  active={sortField === "type"}
                                  sortOrder={sortOrder}
                                  sortField={"type"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              <div className="inline-flex items-center">
                                Frequency
                                <SortButton
                                  active={sortField === "frequency"}
                                  sortOrder={sortOrder}
                                  sortField={"frequency"}
                                  updateSortCriteria={updateSortCriteria}
                                />
                              </div>
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6 cursor-pointer"
                            >
                              <span className="sr-only">Download</span>
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6 cursor-pointer"
                            >
                              <span className="sr-only">Delete</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {reports.map((report) => (
                            <tr key={report.id} className="">
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {new Date(report.createdAt).toDateString()}
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {report.reportFileName}
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {report.type}
                              </td>
                              <td className="px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
                                {report.frequency}
                              </td>
                              <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                                <div
                                  onClick={() => downloadReportFile(report)}
                                  className={classNames(
                                    loading
                                      ? "cursor-not-allowed text-gray-600 hover-text-gray-900"
                                      : "text-amber-600 hover:text-amber-900 cursor-pointer"
                                  )}
                                >
                                  <DownloadIcon className="w-5 h-5" />
                                </div>
                              </td>
                              <td className="relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                                <div
                                  onClick={() => deleteReport(report.id)}
                                  className="text-red-600 cursor-pointer hover:text-red-900"
                                >
                                  <TrashIcon className="w-5 h-5" />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex items-center justify-between py-3 border-t border-gray-200">
                      <div className="flex justify-between flex-1 sm:hidden">
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
                        >
                          Next
                        </a>
                      </div>
                      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="flex flex-row items-center">
                          {loading ? (
                            <p className="text-sm text-gray-700">Loading...</p>
                          ) : totalCount > 0 ? (
                            <>
                              <p className="text-sm text-gray-700">
                                Showing{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber - rowsPerPage + 1}
                                </span>{" "}
                                to{" "}
                                <span className="font-medium">
                                  {rowsPerPage * pageNumber > totalCount
                                    ? totalCount
                                    : rowsPerPage * pageNumber}
                                </span>{" "}
                                of <span className="font-medium">{totalCount}</span> results
                              </p>
                            </>
                          ) : (
                            <p className="text-sm text-gray-700">Showing 0 results.</p>
                          )}
                          <div className="text-sm text-gray-700 inline-flex flex-row items-center ml-1.5">
                            <div>Results per page: </div>
                            <div>
                              <select
                                id="rowsPerPage"
                                name="rowsPerPage"
                                className="h-8 ml-1.5 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-amber-600 text-sm sm:leading-6"
                                value={rowsPerPage}
                                onChange={(e) => updateRowsPerPage(parseInt(e.target.value))}
                                disabled={loading}
                              >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <PageNumbers
                          rowsPerPage={rowsPerPage}
                          totalCount={totalCount}
                          pageNumber={pageNumber}
                          setPageNumber={setPageNumber}
                          newPageNumber={newPageNumber}
                          setNewPageNumber={setNewPageNumber}
                          disabled={loading}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
